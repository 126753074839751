import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import PageTemplate from "../templates/page";

const IndexPage = () => {
	const data = useStaticQuery(graphql`
		query {
			site {
				siteMetadata {
					siteUrl
				}
			}
			pageData: wpPage(slug: { eq: "homepage" }) {
				title
				slug
				seoFieldGroups {
					metaTitle
					metaDescription
					localBusinessSchema
					openGraphDescription
					openGraphTitle
					productSchema
					image {
						altText
						publicUrl
						localFile {
							publicURL
							childImageSharp {
								gatsbyImageData(
									formats: [AUTO, WEBP]
									quality: 100
									transformOptions: { cropFocus: CENTER, fit: CONTAIN }
									layout: CONSTRAINED
									placeholder: BLURRED
								)
								original {
									width
									height
								}
							}
						}
					}
				}
				pageFieldsGroup {
					pageFieldsGroup {
						... on WpPage_Pagefieldsgroup_PageFieldsGroup_BannerSection {
							fieldGroupName
							hideBox
							items {
								backgroundImage {
									altText
									localFile {
										publicURL
										childImageSharp {
											original {
												height
												width
											}
											gatsbyImageData(
												formats: [WEBP]
												quality: 90
												transformOptions: { cropFocus: CENTER, fit: CONTAIN }
												layout: CONSTRAINED
												placeholder: BLURRED
											)
										}
									}
								}
								fieldGroupName
								heading
								link {
									target
									title
									url
								}
							}
						}
						... on WpPage_Pagefieldsgroup_PageFieldsGroup_SectionLayout2 {
							description
							fieldGroupName
							bottomLink {
								target
								title
								url
							}
							heading
							link {
								target
								title
								url
							}
							projectType {
								name
								slug
								uri
								description
								projectTypeFields {
									detailPageDescription
									detailPageTitle
									fieldGroupName
									image {
										altText
										localFile {
											publicURL
											childImageSharp {
												original {
													height
													width
												}
												gatsbyImageData(
													formats: [AUTO, WEBP]
													quality: 100
													transformOptions: { cropFocus: CENTER, fit: CONTAIN }
													layout: CONSTRAINED
													placeholder: BLURRED
												)
											}
										}
									}
								}
								projects {
									nodes {
										title
										slug
									}
								}
							}
						}
						... on WpPage_Pagefieldsgroup_PageFieldsGroup_SectionLayout3 {
							description
							fieldGroupName
							heading
							link {
								target
								title
								url
							}
							items {
								description
								fieldGroupName
								heading
							}
						}
						... on WpPage_Pagefieldsgroup_PageFieldsGroup_SectionLayout4 {
							fieldGroupName
							heading
							testimonials {
								... on WpTestimonial {
									id
									title
									content
								}
							}
							link {
								target
								title
								url
							}
							backgroundImage {
								altText
								localFile {
									publicURL
									childImageSharp {
										original {
											height
											width
										}
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
						... on WpPage_Pagefieldsgroup_PageFieldsGroup_SectionLayout5 {
							description
							fieldGroupName
							heading
							image {
								altText
								localFile {
									publicURL
									childImageSharp {
										original {
											height
											width
										}
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
							link {
								target
								title
								url
							}
						}
						... on WpPage_Pagefieldsgroup_PageFieldsGroup_SectionLayout6 {
							description
							fieldGroupName
							heading
							link {
								target
								title
								url
							}
						}
						... on WpPage_Pagefieldsgroup_PageFieldsGroup_SectionLayout7 {
							description
							fieldGroupName
							heading
							items {
								description
								fieldGroupName
								heading
								image {
									altText
									localFile {
										publicURL
										childImageSharp {
											original {
												height
												width
											}
											gatsbyImageData(
												formats: [AUTO, WEBP]
												quality: 100
												transformOptions: { cropFocus: CENTER, fit: CONTAIN }
												layout: CONSTRAINED
												placeholder: BLURRED
											)
										}
									}
								}
							}
							link {
								target
								title
								url
							}
							link2 {
								target
								title
								url
							}
							link3 {
								target
								title
								url
							}
						}
						... on WpPage_Pagefieldsgroup_PageFieldsGroup_SectionLayout8 {
							fieldGroupName
							heading
							items {
								... on WpFaq {
									id
									title
									slug
									faqFields {
										faqFields {
											answer
											fieldGroupName
											question
										}
									}
								}
							}
							link {
								target
								title
								url
							}
							link2 {
								target
								title
								url
							}
							link3 {
								target
								title
								url
							}
						}
						... on WpPage_Pagefieldsgroup_PageFieldsGroup_SectionLayout9 {
							fieldGroupName
							heading
							items {
								heading
								fieldGroupName
								text
								link {
									target
									title
									url
								}
							}
						}
						... on WpPage_Pagefieldsgroup_PageFieldsGroup_SectionLayout10 {
							fieldGroupName
							heading
							listTitle
							link {
								target
								title
								url
							}
							image {
								altText
								localFile {
									publicURL
									childImageSharp {
										original {
											height
											width
										}
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
							bannerTestimonial {
								... on WpTestimonial {
									id
									title
									content
									featuredImage {
										node {
											altText
											localFile {
												publicURL
												childImageSharp {
													original {
														height
														width
													}
													gatsbyImageData(
														formats: [AUTO, WEBP]
														quality: 100
														transformOptions: {
															cropFocus: CENTER
															fit: CONTAIN
														}
														layout: CONSTRAINED
														placeholder: BLURRED
													)
												}
											}
										}
									}
								}
							}
							testimonials {
								... on WpTestimonial {
									id
									title
									content
									featuredImage {
										node {
											altText
											localFile {
												publicURL
												childImageSharp {
													original {
														height
														width
													}
													gatsbyImageData(
														formats: [AUTO, WEBP]
														quality: 100
														transformOptions: {
															cropFocus: CENTER
															fit: CONTAIN
														}
														layout: CONSTRAINED
														placeholder: BLURRED
													)
												}
											}
										}
									}
									testimonialsFields {
										authorImage {
											altText
											localFile {
												publicURL
												childImageSharp {
													original {
														height
														width
													}
													gatsbyImageData(
														formats: [AUTO, WEBP]
														quality: 100
														transformOptions: {
															cropFocus: CENTER
															fit: CONTAIN
														}
														layout: CONSTRAINED
														placeholder: BLURRED
													)
												}
											}
										}
									}
								}
							}
						}
						... on WpPage_Pagefieldsgroup_PageFieldsGroup_SectionLayout11 {
							description
							fieldGroupName
							blogs {
								... on WpPost {
									id
									title
									featuredImage {
										node {
											altText
											localFile {
												publicURL
												childImageSharp {
													original {
														height
														width
													}
													gatsbyImageData(
														formats: [AUTO, WEBP]
														quality: 100
														transformOptions: {
															cropFocus: CENTER
															fit: CONTAIN
														}
														layout: CONSTRAINED
														placeholder: BLURRED
													)
												}
											}
										}
									}
								}
							}
							heading
						}
						... on WpPage_Pagefieldsgroup_PageFieldsGroup_SectionLayout12 {
							description
							fieldGroupName
							heading
							link {
								target
								title
								url
							}
							column1 {
								description
								fieldGroupName
								image {
									altText
									localFile {
										publicURL
										childImageSharp {
											original {
												height
												width
											}
											gatsbyImageData(
												formats: [AUTO, WEBP]
												quality: 100
												transformOptions: { cropFocus: CENTER, fit: CONTAIN }
												layout: CONSTRAINED
												placeholder: BLURRED
											)
										}
									}
								}
							}
							column2 {
								description
								fieldGroupName
								image {
									altText
									localFile {
										publicURL
										childImageSharp {
											original {
												height
												width
											}
											gatsbyImageData(
												formats: [AUTO, WEBP]
												quality: 100
												transformOptions: { cropFocus: CENTER, fit: CONTAIN }
												layout: CONSTRAINED
												placeholder: BLURRED
											)
										}
									}
								}
							}
						}
						... on WpPage_Pagefieldsgroup_PageFieldsGroup_SectionLayout13 {
							fieldGroupName
							heading
							iframeSrc
							brochure {
								sourceUrl
								mediaItemUrl
							}
							link {
								target
								title
								url
							}
							link2 {
								target
								title
								url
							}
							link3 {
								target
								title
								url
							}
							subheading
						}
					}
				}
			}
		}
	`);

	return <PageTemplate data={{ ...data, isHomePage: true }} />;
};

export default IndexPage;
